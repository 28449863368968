<template>
  <multiselect :multiple="this.multiple" :allowEmpty="this.allowEmpty" @search-change="handleSearch"
      :label="this.label ? this.label : 'name'"
      :track-by="this.trackBy ? this.trackBy : 'id'"
      :disabled="this.disabled"
      :options="(this.optionsData.length > 0) ? this.optionsData : (this.options != undefined ? this.options : [])"
      v-model="selectwh" @input="onselect" :placeholder="placeholder ? placeholder : `type to search`"
      :loading="loading" />
</template>
<script>

export default {
  components: {
  },
  props: {
      multiple: {
          type: Boolean,
          default: false,
      },
      disabled: {
          type: Boolean,
          default: false,
      },
      WarehouseAreaType: {
          type: String,
      },
      Damaged: {
          type: Number,
      },
      WarehouseId: {
          type: Number,
          required:false
      },
      placeholder: {
          type: String
      },
      label: {
          type: String,
      },
      baseUrl: {
          type: String,
      },
      allowEmpty:{
          type:Boolean,
          default:true,
      },
      trackBy: {
          type: String,
      },
      value: {
      },
      options: {
          required: false
      },
      select_options: {
        type: Object,
      }
  },
  data() {
      return {
          typedValue: "",
          timeOuts: null,
          optionsData: [],
          selected: this.value ? this.value : {},
          loading: false,
          selectwh: {
            id: 0,
            name: '',
          },
      };
  },
  methods: {
      handleSearch(v) {
          this.typedValue = v;
          if (this.timeOuts) { clearTimeout(this.timeOuts) }
          this.timeOuts = setTimeout(this.get, 400);
      },
      get() {
          this.loading = true;
          this.$http
              .get(this.baseUrl, {
                  params: {
                        order: "id",
                        sort: "asc",
                        type: this.WarehouseAreaType,
                        warehouse_id: this.WarehouseId,
                        damaged: this.Damaged,
                        query: this.typedValue,
                    }
              })
              .then(resp => {
                console.log('wh_id',this.WarehouseId)
                this.loading = false;
                  if (resp.code == 200) {
                      this.optionsData = resp.data.records;
                      if(this.select_options){
                        var datafilter = this.optionsData.filter((v)=>{
                          return this.select_options.id == v.id
                        })
                        if(datafilter.length >0){
                          this.selectwh = datafilter[0]
                        }
                      }
                  } else {
                      this.optionsData = [];
                      console.log(resp.data);
                  }
              }).catch((error)=>{
                this.loading = false;
                console.log(error)
              });
      },
      onselect(v){
        this.$emit("selected", v);
      }

  },
  watch: {
      value(v) {
          if (v) {
              this.selected = v
          }
      },
      optionsData(newOptions) {
          if (newOptions.length > 0 && !this.selectwh.id) {
              this.selectwh = newOptions[0];
              this.$emit("selected", this.selectwh);
            }
        }
  },
  mounted() {
      if (this.baseUrl != "" && this.baseUrl != undefined) {
          this.get();
      } else if (this.options.length > 0) {
          this.optionsData = this.options
      }

      if(this.select_options){
          this.selectwh = this.select_options
      } else if(this.optionsData.length > 0 && !this.selectwh.id) {
        this.selectwh = this.optionsData[0];
        }
  }
};
</script>
<style>
.multiselect--above .multiselect__content-wrapper{
  bottom: auto !important;
  top: 100% !important;
}

</style>
